body,
#exclude {
  text-decoration: none;
  color: black;
  cursor: auto;
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  border: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #91979da4;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #91979dd2;
}
a:link,
a:visited {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  justify-content: flex-start;
  max-width: 98vw;
  position: relative;
  bottom: 13px;
  min-height: 92vh;
}

.background {
  width: 100%;
  height: 100%;
  position: fixed;
  background-position: center;
  background-size: cover;
  overflow-y: scroll;
}

.todos {
  border-radius: 5px;
  display: flex;
  margin: 5px 5px 5px 5px;
  flex-direction: column;
  padding: 8px;
  /* background-color: rgb(234, 238, 238); */
  background-color: transparent;
  overflow-x: scroll;
  width: 45%;
  max-height: 86vh;
  min-width: 210px;
}

@media (max-width: 700px) {
  .todos {
    width: 95%;
    margin-bottom: 10px;
    max-height: 47vh;
  }

  .container {
    flex-wrap: wrap;
    overflow-y: scroll;
  }
}

img[alt="drawing"] {
  width: 200px;
}

.done {
  color: green;
}

.sideBar {
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
  width: 30px;
}

.sideBar1 {
  width: 220px;
  transition: all 0.4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.sideBar:hover {
  width: 220px;
  transition: all 0.4s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}
